var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roleList"},[_c('div',{staticClass:"roleList-body"},[_c('div',{staticClass:"roleList-l"},[_c('div',{staticClass:"tree-search"},[_c('v-input',{attrs:{"prefix-icon":"el-icon-search","placeholder":"请输入名称"},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('div',{staticClass:"tree-group"},[_c('button-tree',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.treeLoading),expression:"treeLoading"}],key:_vm.keyTree,ref:"buttonTree",attrs:{"data":_vm.treeData,"defaultProps":_vm.treeProps,"btnOps":_vm.btnOps,"filterText":_vm.filterText,"changeOnSelect":true,"filterNodeMethod":_vm.filterNodeMethod,"defaultExpandedKeys":_vm.defaultExpandedKeys,"currentNodeKey":_vm.currentNodeKey,"highlightCurrent":true,"expand-on-click-node":false},on:{"buttonClick":_vm.buttonNode,"nodeClick":_vm.nodeClick}})],1)]),_c('div',{staticClass:"roleList-r"},[_c('div',{staticClass:"title"},[_vm._v("角色详情")]),_c('div',{staticClass:"tree-group"},[_c('button-tree',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.treeRoleLoading),expression:"treeRoleLoading"}],key:_vm.keyRoleTree,ref:"buttonRoleTree",attrs:{"data":_vm.treeRoleData,"defaultProps":_vm.treeRoleProps,"btnOps":_vm.btnRoleOps,"defaultExpandedKeys":_vm.defaultRoleExpandedKeys,"currentNodeKey":_vm.currentRoleNodeKey,"highlightCurrent":true,"expand-on-click-node":false},on:{"buttonClick":_vm.buttonRoleNode}})],1)]),_vm._m(0)]),_c('v-dialog',{attrs:{"title":_vm.manageTitle},on:{"confirm":_vm.manageConfirm,"close":_vm.manageClose},model:{value:(_vm.isManageGroup),callback:function ($$v) {_vm.isManageGroup=$$v},expression:"isManageGroup"}},[_c('form-panel',{ref:"formPanel",attrs:{"hasHeader":false,"footerShow":false,"form":_vm.manageGroupDialog}},[_c('el-form-item',{attrs:{"label":"管理组名称","rules":[
            { required: true, message: '请填写管理组名称', trigger: 'blur' },
          ],"prop":"name"}},[_c('v-input',{attrs:{"width":_vm.width,"disabled":_vm.isManageDisabled,"placeholder":"请填写名称"},model:{value:(_vm.manageGroupDialog.name),callback:function ($$v) {_vm.$set(_vm.manageGroupDialog, "name", $$v)},expression:"manageGroupDialog.name"}})],1),_c('el-form-item',{attrs:{"label":"管理组类型","rules":[
            { required: true, message: '请选择管理组类型', trigger: 'blur' },
          ],"prop":"type"}},[_c('v-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.typeLoading),expression:"typeLoading"}],attrs:{"disabled":_vm.isManageDisabled,"width":_vm.width,"options":_vm.manageTypeOps},model:{value:(_vm.manageGroupDialog.type),callback:function ($$v) {_vm.$set(_vm.manageGroupDialog, "type", $$v)},expression:"manageGroupDialog.type"}})],1),_c('el-form-item',{attrs:{"label":"管辖范围","rules":[
            { required: true, message: '请填写管辖范围', trigger: 'blur' },
          ],"prop":"includeSpaces"}},[_c('div',{style:(`width:${_vm.width}px`)},[_c('v-cascader',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.includeLoading),expression:"includeLoading"}],attrs:{"placeholder":"默认表示全部","width":_vm.width,"options":_vm.manageSectionOps,"props":_vm.manageProps},model:{value:(_vm.manageGroupDialog.includeSpaces),callback:function ($$v) {_vm.$set(_vm.manageGroupDialog, "includeSpaces", $$v)},expression:"manageGroupDialog.includeSpaces"}})],1)])],1)],1),_c('v-dialog',{attrs:{"title":"菜单权限","sureTxt":"保存"},on:{"confirm":_vm.confirmMenu,"close":_vm.closeMenu},model:{value:(_vm.isMenuRole),callback:function ($$v) {_vm.isMenuRole=$$v},expression:"isMenuRole"}},[_c('v-select',{staticStyle:{"margin-bottom":"20px"},attrs:{"label":"选择模块：","options":_vm.modularObj},on:{"change":_vm.domainIdChange},model:{value:(_vm.domainId),callback:function ($$v) {_vm.domainId=$$v},expression:"domainId"}}),_c('v-cascader',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.menuLoading),expression:"menuLoading"}],key:_vm.cascaderKey,attrs:{"placeholder":"默认表示全部","width":_vm.width,"options":_vm.sectionOps,"props":_vm.props},model:{value:(_vm.spaceIds),callback:function ($$v) {_vm.spaceIds=$$v},expression:"spaceIds"}})],1),_c('v-dialog',{attrs:{"title":_vm.title},on:{"confirm":_vm.roleConfirm,"close":_vm.roleClose},model:{value:(_vm.isAddRole),callback:function ($$v) {_vm.isAddRole=$$v},expression:"isAddRole"}},[_c('form-panel',{ref:"formName",attrs:{"hasHeader":false,"footerShow":false,"form":_vm.roleDialog}},[_c('el-form-item',{attrs:{"label":"角色类型","rules":[
            { required: true, message: '请选择角色类型', trigger: 'blur' },
          ],"prop":"roleAlias"}},[_c('v-select',{attrs:{"options":_vm.roleTypeListOps},model:{value:(_vm.roleDialog.roleAlias),callback:function ($$v) {_vm.$set(_vm.roleDialog, "roleAlias", $$v)},expression:"roleDialog.roleAlias"}})],1),_c('el-form-item',{attrs:{"label":"角色名称","rules":[{ required: true, message: '请填写名称', trigger: 'blur' }],"prop":"roleName"}},[_c('v-input',{attrs:{"placeholder":"请填写名称"},model:{value:(_vm.roleDialog.roleName),callback:function ($$v) {_vm.$set(_vm.roleDialog, "roleName", $$v)},expression:"roleDialog.roleName"}})],1)],1)],1),_c('v-dialog',{attrs:{"width":"50%","title":"模块权限"},on:{"close":_vm.cancelModule,"confirm":_vm.confirmModule},model:{value:(_vm.isShowModule),callback:function ($$v) {_vm.isShowModule=$$v},expression:"isShowModule"}},[_c('form-panel',{ref:"moduleForm",attrs:{"hasHeader":false,"footerShow":false,"form":_vm.moduleDialog}},[_c('el-form-item',{attrs:{"label":"选择模块","rules":[
            { required: false, message: '请选择角色类型', trigger: 'blur' },
          ]}},[_c('v-select',{attrs:{"width":_vm.formWidth,"options":_vm.moduleListOps},on:{"change":_vm.handelChangeModuleName},model:{value:(_vm.moduleName),callback:function ($$v) {_vm.moduleName=$$v},expression:"moduleName"}})],1),_c('el-form-item',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"font-size":"16px","font-weight":"700","color":"#000"}},[_vm._v(" 导览页配置 ")])]},proxy:true}])}),_c('el-form-item',{attrs:{"label":"图标名称","rules":[
            { required: true, message: '请填写图标名称', trigger: 'blur' },
          ],"prop":"guideConfig.name"}},[_c('v-input',{attrs:{"width":_vm.formWidth,"placeholder":"请输入"},model:{value:(_vm.moduleDialog.guideConfig.name),callback:function ($$v) {_vm.$set(_vm.moduleDialog.guideConfig, "name", $$v)},expression:"moduleDialog.guideConfig.name"}})],1),_c('el-form-item',{attrs:{"label":"选择图标","rules":[{ required: true, message: '请上传图标', trigger: 'blur' }],"prop":"guideConfig.icon"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.moduleDialog.guideConfig.icon},on:{"update:imgUrls":function($event){return _vm.$set(_vm.moduleDialog.guideConfig, "icon", $event)},"update:img-urls":function($event){return _vm.$set(_vm.moduleDialog.guideConfig, "icon", $event)}}})],1),_c('el-form-item',{attrs:{"label":"是否显示","rules":[{ required: true, message: '请上传图标', trigger: 'blur' }],"prop":"isSelected"}},[_c('v-radio',{attrs:{"radioObj":_vm.isShowFieldS},model:{value:(_vm.moduleDialog.isSelected),callback:function ($$v) {_vm.$set(_vm.moduleDialog, "isSelected", $$v)},expression:"moduleDialog.isSelected"}})],1),_c('el-form-item',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"font-size":"16px","font-weight":"700","color":"#000"}},[_vm._v(" 模块页配置 ")])]},proxy:true}])}),_c('el-form-item',{attrs:{"label":"模块名称","rules":[
            { required: true, message: '请填写模块名称', trigger: 'blur' },
          ],"prop":"platformConfig.name"}},[_c('v-input',{attrs:{"width":_vm.formWidth,"placeholder":"请输入"},model:{value:(_vm.moduleDialog.platformConfig.name),callback:function ($$v) {_vm.$set(_vm.moduleDialog.platformConfig, "name", $$v)},expression:"moduleDialog.platformConfig.name"}})],1),_c('el-form-item',{attrs:{"label":"模块URL","rules":[
            { required: true, message: '请填写模块URL', trigger: 'blur' },
          ],"prop":"platformConfig.url"}},[_c('v-input',{attrs:{"width":_vm.formWidth,"placeholder":"请输入"},model:{value:(_vm.moduleDialog.platformConfig.url),callback:function ($$v) {_vm.$set(_vm.moduleDialog.platformConfig, "url", $$v)},expression:"moduleDialog.platformConfig.url"}})],1),_c('el-form-item',{attrs:{"label":"模块图标","rules":[
            { required: false, message: '请上传模块图标', trigger: 'blur' },
          ],"prop":"platformConfig.icon"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.moduleDialog.platformConfig.icon},on:{"update:imgUrls":function($event){return _vm.$set(_vm.moduleDialog.platformConfig, "icon", $event)},"update:img-urls":function($event){return _vm.$set(_vm.moduleDialog.platformConfig, "icon", $event)}}})],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"roleList-r"},[_c('div',{staticClass:"title"},[_vm._v("菜单配置")]),_c('div',{staticClass:"main"})])
}]

export { render, staticRenderFns }