<template>
  <div class="roleList">
    <div class="roleList-body">
      <div class="roleList-l">
        <!-- 搜索框 -->
        <div class="tree-search">
          <v-input
            v-model="filterText"
            prefix-icon="el-icon-search"
            placeholder="请输入名称"
          />
        </div>
        <!-- 类型选择 -->
        <!-- 控件树 -->
        <div class="tree-group">
          <button-tree
            ref="buttonTree"
            v-loading="treeLoading"
            :key="keyTree"
            :data="treeData"
            :defaultProps="treeProps"
            :btnOps="btnOps"
            :filterText="filterText"
            :changeOnSelect="true"
            :filterNodeMethod="filterNodeMethod"
            :defaultExpandedKeys="defaultExpandedKeys"
            :currentNodeKey="currentNodeKey"
            :highlightCurrent="true"
            :expand-on-click-node="false"
            @buttonClick="buttonNode"
            @nodeClick="nodeClick"
          />
        </div>
      </div>

      <div class="roleList-r">
        <div class="title">角色详情</div>
        <div class="tree-group">
          <!-- :filterText="filterText"
            :filterNodeMethod="filterNodeMethod" -->
          <button-tree
            ref="buttonRoleTree"
            v-loading="treeRoleLoading"
            :key="keyRoleTree"
            :data="treeRoleData"
            :defaultProps="treeRoleProps"
            :btnOps="btnRoleOps"
            :defaultExpandedKeys="defaultRoleExpandedKeys"
            :currentNodeKey="currentRoleNodeKey"
            :highlightCurrent="true"
            :expand-on-click-node="false"
            @buttonClick="buttonRoleNode"
          />
        </div>
      </div>

      <div class="roleList-r">
        <div class="title">菜单配置</div>
        <div class="main"></div>
      </div>
    </div>

    <!-- 左侧树结构对应弹框 -->
    <v-dialog
      v-model="isManageGroup"
      :title="manageTitle"
      @confirm="manageConfirm"
      @close="manageClose"
    >
      <form-panel
        ref="formPanel"
        :hasHeader="false"
        :footerShow="false"
        :form="manageGroupDialog"
      >
        <el-form-item
          label="管理组名称"
          :rules="[
            { required: true, message: '请填写管理组名称', trigger: 'blur' },
          ]"
          prop="name"
        >
          <v-input
            :width="width"
            :disabled="isManageDisabled"
            placeholder="请填写名称"
            v-model="manageGroupDialog.name"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="管理组类型"
          :rules="[
            { required: true, message: '请选择管理组类型', trigger: 'blur' },
          ]"
          prop="type"
        >
          <v-select
            :disabled="isManageDisabled"
            v-loading="typeLoading"
            :width="width"
            :options="manageTypeOps"
            v-model="manageGroupDialog.type"
          />
        </el-form-item>
        <el-form-item
          label="管辖范围"
          :rules="[
            { required: true, message: '请填写管辖范围', trigger: 'blur' },
          ]"
          prop="includeSpaces"
        >
          <div :style="`width:${width}px`">
            <v-cascader
              v-loading="includeLoading"
              placeholder="默认表示全部"
              :width="width"
              v-model="manageGroupDialog.includeSpaces"
              :options="manageSectionOps"
              :props="manageProps"
            />
          </div>
        </el-form-item>
      </form-panel>
    </v-dialog>

    <!-- 中间树结构对应弹框 -->
    <v-dialog
      v-model="isMenuRole"
      title="菜单权限"
      sureTxt="保存"
      @confirm="confirmMenu"
      @close="closeMenu"
    >
      <v-select
        label="选择模块："
        @change="domainIdChange"
        :options="modularObj"
        v-model="domainId"
        style="margin-bottom: 20px"
      />
      <v-cascader
        :key="cascaderKey"
        v-loading="menuLoading"
        placeholder="默认表示全部"
        :width="width"
        v-model="spaceIds"
        :options="sectionOps"
        :props="props"
      />
    </v-dialog>

    <v-dialog
      v-model="isAddRole"
      :title="title"
      @confirm="roleConfirm"
      @close="roleClose"
    >
      <form-panel
        ref="formName"
        :hasHeader="false"
        :footerShow="false"
        :form="roleDialog"
      >
        <el-form-item
          label="角色类型"
          :rules="[
            { required: true, message: '请选择角色类型', trigger: 'blur' },
          ]"
          prop="roleAlias"
        >
          <v-select :options="roleTypeListOps" v-model="roleDialog.roleAlias" />
        </el-form-item>
        <el-form-item
          label="角色名称"
          :rules="[{ required: true, message: '请填写名称', trigger: 'blur' }]"
          prop="roleName"
        >
          <v-input
            placeholder="请填写名称"
            v-model="roleDialog.roleName"
          ></v-input>
        </el-form-item>
      </form-panel>
    </v-dialog>
    <!-- 模块权限弹窗 -->
    <v-dialog
      width="50%"
      v-model="isShowModule"
      title="模块权限"
      @close="cancelModule"
      @confirm="confirmModule"
    >
      <form-panel
        ref="moduleForm"
        :hasHeader="false"
        :footerShow="false"
        :form="moduleDialog"
      >
        <el-form-item
          label="选择模块"
          :rules="[
            { required: false, message: '请选择角色类型', trigger: 'blur' },
          ]"
        >
          <v-select
            :width="formWidth"
            :options="moduleListOps"
            v-model="moduleName"
            @change="handelChangeModuleName"
          />
        </el-form-item>
        <el-form-item>
          <template #label>
            <div style="font-size: 16px; font-weight: 700; color: #000">
              导览页配置
            </div>
          </template>
        </el-form-item>

        <el-form-item
          label="图标名称"
          :rules="[
            { required: true, message: '请填写图标名称', trigger: 'blur' },
          ]"
          prop="guideConfig.name"
        >
          <v-input
            :width="formWidth"
            placeholder="请输入"
            v-model="moduleDialog.guideConfig.name"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="选择图标"
          :rules="[{ required: true, message: '请上传图标', trigger: 'blur' }]"
          prop="guideConfig.icon"
        >
          <v-upload :limit="1" :imgUrls.sync="moduleDialog.guideConfig.icon" />
        </el-form-item>
        <el-form-item
          label="是否显示"
          :rules="[{ required: true, message: '请上传图标', trigger: 'blur' }]"
          prop="isSelected"
        >
          <v-radio
            :radioObj="isShowFieldS"
            v-model="moduleDialog.isSelected"
          ></v-radio>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div style="font-size: 16px; font-weight: 700; color: #000">
              模块页配置
            </div>
          </template>
        </el-form-item>

        <el-form-item
          label="模块名称"
          :rules="[
            { required: true, message: '请填写模块名称', trigger: 'blur' },
          ]"
          prop="platformConfig.name"
        >
          <v-input
            :width="formWidth"
            placeholder="请输入"
            v-model="moduleDialog.platformConfig.name"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="模块URL"
          :rules="[
            { required: true, message: '请填写模块URL', trigger: 'blur' },
          ]"
          prop="platformConfig.url"
        >
          <v-input
            :width="formWidth"
            placeholder="请输入"
            v-model="moduleDialog.platformConfig.url"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="模块图标"
          :rules="[
            { required: false, message: '请上传模块图标', trigger: 'blur' },
          ]"
          prop="platformConfig.icon"
        >
          <v-upload
            :limit="1"
            :imgUrls.sync="moduleDialog.platformConfig.icon"
          />
        </el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import {
  getManageGroupTreeUrl,
  deleteManageGroupUrl,
  getManageGroupTypeListUrl,
  getManageGroupIncludeSpaceListUrl,
  getManageGroupDetailUrl,
  addManageGroupUrl,
  modifyManageGroupUrl,
  roleListUrl,
  roleDetailUrl,
  addRoleUrl,
  deleteRoleUrl,
  menuListUrl,
  setMenuUrl,
  menuDetailUrl,
  getModuleDetail,
  editModuleUrl,
} from "./api.js";
import ButtonTree from "@/components/bussiness/button-tree/button-tree.vue";

import { newArr } from "@/utils/utils.js";
import {
  btnOps,
  btnRoleOps,
  roleTypeMap,
  roleTypeOps,
  roleTypeList,
  modularObj,
  modularObjMap,
  modularObjOps,
  moduleListOps,
  moduleListMap,
} from "./map";

export default {
  name: "roleList",
  components: {
    "button-tree": ButtonTree,
  },
  data() {
    return {
      moduleName: 0, //模块名称
      moduleConfigList: [], //模块权限详情数据
      moduleListOps: moduleListOps(),
      isShowFieldS: [
        { name: 1, value: "是" },
        { name: 0, value: "否" },
      ],
      formWidth: 300,
      isShowModule: false,
      cascaderKey: 0,
      domainId: 0,
      modularObj,
      modularObjOps,
      isManageGroup: false,
      isManageDisabled: false,
      manageSectionOps: [], // 管辖范围选项数据
      includeSpacesData: [], // 管辖范围选项数据-最后一层级数据
      manageTypeOps: [],
      manageTitle: "",
      manageProps: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      manageGroupDialog: {
        parentId: "", // 父级id
        type: undefined, // 管理组类型
        name: "", // 管理组名称
        includeSpaces: [], // 管辖范围
      },
      typeLoading: false,
      includeLoading: false,
      // 树结构数据
      filterText: "",
      treeLoading: false,
      keyTree: 0,
      btnOps,
      treeData: [],
      defaultExpandedKeys: [],
      currentNodeKey: "",
      treeProps: {
        id: "id", // 必须
        parentId: "parentId",
        children: "children", // 必须
        label: "name", // 必须
        btn: "btn", // 必须
      },
      treeShow: true,
      /* 角色详情 */
      manageGroupId: "", // 管理组id
      isMenuRole: false,
      props: {
        multiple: true,
        label: "title",
        value: "id",
        children: "children",
      },
      width: 250,
      title: "新增角色",
      isStop: 1, // 防止重复提交
      isAddRole: false, // 是否显示新增/编辑角色弹框
      roleTypeMap,
      roleTypeListOps: [],
      roleTypeList,
      roleDialog: {
        id: "",
        parentId: "",
        roleAlias: "",
        roleName: "",
      },
      roleDetail: {}, // 编辑角色时获取角色详情
      roleIds: "", // 角色id
      tenantId: "",
      menuLoading: false,
      spaceIds: [], // 绑定值
      sectionOps: [], // 菜单选项数据
      sectionData: [], // 菜单选项数据-最后一层级数据
      // 树结构数据
      treeRoleLoading: false,
      keyRoleTree: 0,
      treeRoleData: [],
      btnRoleOps,
      defaultRoleExpandedKeys: [],
      currentRoleNodeKey: "",
      treeRoleProps: {
        id: "id", // 必须
        parentId: "parentId",
        children: "children", // 必须
        label: "roleName", // 必须
        btn: "limits", // 必须
      },
      moduleDialog: {
        id: "", //数据id
        guideConfig: {
          name: "",
          icon: "",
        },
        platformConfig: {
          name: "", //模块名称
          icon: "", //模块图标
          domain: "", //模块名称
          url: "", //模块url
        },
        isSelected: 0, //是否显示 1是 0否
      },
    };
  },
  watch: {
    isAddRole: {
      handler(val) {
        if (!val) {
          this.roleDialog = {
            parentId: "",
            roleAlias: "",
            roleName: "",
          };
        }
        if (this.$refs.formName) {
          this.$refs.formName.clearValidate();
        }
      },
    },
    isManageGroup: {
      handler(val) {
        if (!val) {
          this.manageGroupDialog = {
            parentId: "", // 父级id
            type: undefined, // 管理组类型
            name: "", // 管理组名称
            includeSpaces: [], // 管辖范围
          };
        }
        if (this.$refs.formPanel) {
          this.$refs.formPanel.clearValidate();
        }
      },
    },
  },
  mounted() {
    this.getTreeList();
    this.getMenuList();
  },
  methods: {
    //确认模块权限配置
    confirmModule() {
      const result = this.$refs.moduleForm.validate();
      if (result) {
        this.$axios.post(editModuleUrl, this.moduleDialog).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
          }
          this.isShowModule = false;
        });
      }
    },
    //取消
    cancelModule() {
      this.moduleName = 0;
    },
    async domainIdChange(value) {
      await this.getMenuList(value, this.tenantId);
      await this.getMenuDetail(this.roleIds, value);
    },

    /** 左侧树节点相关 */
    // 点击节点
    async nodeClick(data, node, nodeVue) {
      this.treeRoleData = [];
      this.manageGroupId = data.id;
      await this.getRoleTreeList();
      // 点击树节点文字
      //   /* data 节点数据对象, node 节点的vue虚拟对象, nodeVue 节点组件本身 */
      // const val = data && (data[this.treeProps.id] || undefined);
      // this.defaultExpandedKeys = val ? [val] : [];
      // this.currentNodeKey = val;
      // if (data.roleAlias == "tenant") {
      //   this.roleDetail = data;
      // } else {
      //   this.getRoleDetail(data.id);
      // }
    },
    // 点击按钮
    async buttonNode(data, item) {
      if (item.code == 1 || item.code == 2) {
        this.isManageGroup = true;
        this.getManageGroupTypeList(data.id);
        await this.getManageGroupIncludeSpaceList(data.id);
      }
      if (item.code == 1) {
        this.manageTitle = "新增管理组";
        this.isManageDisabled = false;
      }
      if (item.code == 2) {
        this.manageTitle = "编辑管理组";
        this.isManageDisabled = true;
        this.getManageGroupDetail(data.id);
      }
      if (item.code == 3) {
        this.$confirm("是否确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          await this.deleteManage(data.id);
          this.getTreeList();
        });
      }
      if (item.code == 4) {
        // 新增角色
        this.title = "新增角色";
        this.isAddRole = true;
        this.roleDialog.parentId = 0;
        this.roleTypeListOps = this.roleTypeList.slice(0, 1);
      }
    },
    // 过滤方法
    filterNodeMethod(value, data) {
      // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true;
      return data[this.treeProps.label].indexOf(value) !== -1;
    },
    // 获取树节点列表
    async getTreeList() {
      // 获取树节点数据
      this.treeLoading = true;
      await this.$axios
        .get(getManageGroupTreeUrl)
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.treeData = res.data;
          }
        })
        .finally(() => {
          this.manageGroupId = "";
          this.treeRoleData = [];
          this.treeLoading = false;
        });
    },

    // 删除管理组请求
    async deleteManage(spaceId) {
      const res = await this.$axios.post(deleteManageGroupUrl, null, {
        params: {
          spaceId,
        },
      });
      if (res.code == 200 && res.success) {
        this.$message.success("操作成功");
      }
    },
    /* 左侧弹出框 */
    async manageConfirm() {
      let url = "";
      let params = {};
      params.includeSpaces = this.manageGroupDialog.includeSpaces
        .map((a) => a[a.length - 1])
        .join(",");
      if (this.manageTitle == "新增管理组") {
        url = addManageGroupUrl;
        params.parentId = this.manageGroupId;
        params.name = this.manageGroupDialog.name;
        params.type = this.manageGroupDialog.type;
      }
      if (this.manageTitle == "编辑管理组") {
        url = modifyManageGroupUrl;
        params.spaceId = this.manageGroupId;
      }
      const res = await this.$axios.post(url, params);
      if (res.code == 200 && res.success) {
        this.$message.success("操作成功");
        this.isManageGroup = false;
        this.getTreeList();
      }
    },
    manageClose() {},
    // 获取详情
    getManageGroupDetail(spaceId) {
      this.detailLoading = true;
      this.$axios
        .get(getManageGroupDetailUrl, {
          params: {
            spaceId,
          },
        })
        .then((res) => {
          if (res.code == 200 && res.success) {
            Object.keys(this.manageGroupDialog).forEach((key) => {
              if (key === "includeSpaces") {
                this.manageGroupDialog[key] = res.data[key]
                  ? res.data[key].split(",").map((v) => {
                      console.log(v, "v---");
                      for (let i = 0; i < this.includeSpacesData.length; i++) {
                        if (this.includeSpacesData[i].id === v) {
                          return [
                            ...this.includeSpacesData[i].treeIds,
                            this.includeSpacesData[i].id,
                          ];
                        }
                      }
                    })
                  : [];
                // console.log(this.manageGroupDialog[key], "---fda0--");
                return;
              }
              this.manageGroupDialog[key] =
                res.data[key] || this.manageGroupDialog[key];
            });
          }
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
    // 获取管理组类型
    async getManageGroupTypeList(parentId) {
      this.typeLoading = true;
      this.manageTypeOps = [];
      let res = await this.$axios.get(`${getManageGroupTypeListUrl}`, {
        params: {
          parentId,
        },
      });
      if (res.code == 200) {
        this.manageTypeOps =
          res.data &&
          res.data.map((v) => ({
            value: v.code,
            label: v.text,
          }));
      }
      this.typeLoading = false;
    },
    // 获取管理组管辖范围
    async getManageGroupIncludeSpaceList(parentId) {
      this.includeLoading = true;
      this.manageSectionOps = [];
      this.includeSpacesData = [];
      let res = await this.$axios.get(`${getManageGroupIncludeSpaceListUrl}`, {
        params: {
          parentId,
        },
      });
      if (res.code == 200) {
        this.manageSectionOps = res.data.data;
        this.dealDataAddParentIds(this.manageSectionOps);
        console.log(this.manageSectionOps, this.includeSpacesData, "fjadl---");
      }
      this.includeLoading = false;
    },

    /* 中间角色树 */
    // 获取树节点列表
    async getRoleTreeList() {
      // 获取树节点数据
      this.treeRoleLoading = true;
      const res = await this.$axios.get(roleListUrl, {
        params: {
          manageGroupId: this.manageGroupId,
        },
      });
      if (res.code == 200 && res.success) {
        const data = res.data;
        this.funcArray(data);
        this.treeRoleData = data;
      }
      this.roleIds = "";
      this.treeRoleLoading = false;
    },
    // 递归设置节点按钮权限
    funcArray(tdata) {
      let _this = this;
      if (Array.isArray(tdata) && tdata.length > 0) {
        tdata.forEach(function (v, i) {
          // if (v.roleAlias == "tenant") {
          //   v.limits = ["powerBtn", "addMemberBtn"];
          // }
          if (v.roleAlias == "administrator") {
            v.limits = ["powerBtn", "deteleBtn", "editBtn", "addMemberBtn"];
          }
          if (v.roleAlias == "user") {
            v.limits = ["powerBtn", "deteleBtn", "editBtn"];
          }
          _this.funcArray(v.children);
        });
      }
    },
    async buttonRoleNode(data, item) {
      console.log(data.tenantId, item, "++++=");
      if (item.code == 1) {
        this.title = "新增角色";
        this.isAddRole = true;
        // if (data.roleAlias == "tenant") {
        //   this.roleDialog.parentId = 0;
        //   this.roleTypeListOps = this.roleTypeList.slice(0, 1);
        // }
        if (data.roleAlias == "administrator") {
          this.roleDialog.parentId = data.id;
          this.roleTypeListOps = this.roleTypeList.slice(1, 2);
        }
      }
      if (item.code == 2) {
        this.title = "编辑角色";
        this.isAddRole = true;
        this.roleDialog.id = data.id;
        if (data.roleAlias == "user") {
          this.roleDialog.parentId = "";
          this.roleTypeListOps = this.roleTypeList.slice(1, 2);
        }
        if (data.roleAlias == "administrator") {
          this.roleDialog.parentId = "";
          this.roleTypeListOps = this.roleTypeList.slice(0, 1);
        }
        await this.getRoleDetail(data.id);
        this.roleDialog = this.roleDetail;
      }
      if (item.code == 3) {
        this.$confirm("是否确定删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          await this.deleteRole(data.id);
          this.getRoleTreeList();
        });
      }
      if (item.code == 4) {
        this.roleIds = data.id;
        this.tenantId = data.tenantId;
        this.isMenuRole = true;
        await this.getMenuList(this.domainId, this.tenantId);
        await this.getMenuDetail(data.id, 0);
      }
      if (item.code == 5) {
        this.roleIds = data.id;
        this.getModuleDetail();
        this.isShowModule = true;
      }
    },
    //获取模块权限详情
    getModuleDetail() {
      const params = {
        roleId: this.roleIds,
      };
      this.$axios.get(getModuleDetail, { params }).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          this.moduleConfigList = JSON.parse(JSON.stringify(data));
          this.moduleConfigList.forEach((item) => {
            if (item.platformConfig.domain === this.moduleName) {
              Object.keys(this.moduleDialog).forEach((key) => {
                this.moduleDialog[key] = item[key];
              });
            }
          });
        }
      });
    },
    //选择模块回显对应数据
    async handelChangeModuleName(arg) {
      this.moduleDialog = {
        id: "", //数据id
        guideConfig: {
          name: "",
          icon: "",
        },
        platformConfig: {
          name: "", //模块名称
          icon: "", //模块图标
          domain: "", //模块名称
          url: "", //模块url
        },
        isSelected: 0, //是否显示 1是 0否
      };
      await this.getModuleDetail();
    },
    // 角色的菜单权限详情
    async getMenuDetail(roleId, domainId) {
      this.menuLoading = true;
      let params = {
        roleId,
        domain: domainId,
      };
      let res = await this.$axios.get(`${menuDetailUrl}`, { params });
      if (res.code == 200) {
        const spaceIds = [];
        res.data &&
          res.data.forEach((v) => {
            for (let i = 0; i < this.sectionData.length; i++) {
              if (this.sectionData[i].id === v) {
                if (this.sectionData[i].treeIds) {
                  spaceIds.push([
                    ...this.sectionData[i].treeIds,
                    this.sectionData[i].id,
                  ]);
                } else {
                  spaceIds.push([this.sectionData[i].id]);
                }
              }
            }
          });
        this.spaceIds = spaceIds;
      }
      // if (res.code == 200) {
      //   this.manageSectionOps = res.data;
      //   this.dealDataAddParentIds(this.manageSectionOps);
      //   console.log(this.manageSectionOps, this.includeSpacesData, "fjadl---");
      // }
      this.menuLoading = false;
    },
    // 删除节点请求
    async deleteRole(ids) {
      const res = await this.$axios.post(`${deleteRoleUrl}`, null, {
        params: {
          ids,
        },
      });
      if (res.code == 200 && res.success) {
        this.$message.success("操作成功");
      }
    },

    /* 角色弹出框 */
    // 关闭菜单弹框
    closeMenu() {
      this.isMenuRole = false;
      this.tenantId = "";
    },
    // 获取菜单接口
    async getMenuList(id, tenantId) {
      let params = {
        domain: id || 0,
        tenantId: tenantId,
      };
      let res = await this.$axios.get(`${menuListUrl}`, { params: params });
      this.cascaderKey++;
      if (res.code == 200) {
        this.sectionOps = res.data;
        this.dealDataAddParentIds(this.sectionOps, "sectionData");
      }
    },
    // 确认为角色配置该菜单权限
    async confirmMenu() {
      let arr = [];
      let newArrList = [];
      console.log(this.spaceIds, "this.spaceIds");
      this.spaceIds.forEach((ele) => {
        ele.forEach((item) => {
          newArrList.push(item);
        });
      });
      arr = newArr(newArrList);
      let params = {
        roleIds: [this.roleIds],
        menuIds: arr,
        domain: this.domainId,
      };
      await this.$axios.post(`${setMenuUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.spaceIds = [];
          this.domainId = 0;
          this.isMenuRole = false;
        }
      });
      this.tenantId = "";
    },
    // 新增/编辑角色
    async setRoleRequest() {
      let params = {
        manageGroupId: this.manageGroupId,
        roleName: this.roleDialog.roleName,
        sort: 1,
        roleAlias: this.roleDialog.roleAlias,
      };
      if (this.title == "新增角色") {
        params.parentId = this.roleDialog.parentId;
      }
      if (this.title == "编辑角色") {
        params.id = this.roleDialog.id;
      }
      const res = await this.$axios.post(`${addRoleUrl}`, params);
      if (res.code == 200 && res.success) {
        this.$message.success("操作成功");
      }
    },
    // 确认新增/编辑角色
    async roleConfirm() {
      if (this.isStop == 1) {
        this.isStop = 0;
        const valid = this.$refs.formName.validate();
        if (valid) {
          await this.setRoleRequest();
          this.isAddRole = false;
          await this.getRoleTreeList();
        } else {
          this.isAddRole = true;
        }
        this.isStop = 1;
      }
    },
    // 获取节点详情请求
    async getRoleDetail(id) {
      let params = {
        id: id,
      };
      await this.$axios.post(`${roleDetailUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.roleDetail = res.data;
        }
      });
    },
    // 关闭弹框
    roleClose() {},

    /* 方法 */

    // 数据添加 parentIds
    dealDataAddParentIds(
      res,
      str = "includeSpacesData",
      curLevel = 1,
      ids = []
    ) {
      res.forEach((item) => {
        if (curLevel > 1) {
          item.treeIds = [...ids, item.parentId];
        }
        if (item.children && item.children.length > 0) {
          this.dealDataAddParentIds(
            item.children,
            str,
            curLevel + 1,
            item.treeIds
          );
        } else {
          this[str].push(item);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.roleList {
  box-sizing: border-box;
  height: 100%;
  .roleList-body {
    padding: 50px 0 20px 0;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    .roleList-l {
      width: 240px;
      height: 100%;
      .tree-search {
        text-align: center;
        ::v-deep .v-control {
          width: 100%;
          .el-input {
            width: 100% !important;
            .el-input__inner {
              outline: none;
              padding: 2px 10px 2px 30px;
              height: 33px;
            }
            .el-input__icon {
              line-height: 33px;
            }
            .el-input__suffix {
              line-height: 33px;
            }
          }
        }
      }
      .tree-group {
        box-sizing: border-box;
        padding-top: 5px;
        height: calc(100% - 33px);
        width: 240px;
      }
    }
    .roleList-r {
      width: 400px;
      position: relative;
      margin-left: 30px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      .title {
        border-bottom: 1px solid #dcdfe6;
        height: 33px;
        padding: 0 20px;
        line-height: 33px;
      }
      .tree-group {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding-top: 5px;
        height: calc(100% - 33px);
      }
      .save {
        padding: 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .treeSwith {
    cursor: pointer;
    display: inline-block;
    position: relative;
    height: 100%;
    vertical-align: top;
    width: 36px;
    img {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 36px;
      height: 80px;
    }
    &-bar {
      width: 8px;
      height: 160px;
      background: linear-gradient(
        to top left,
        rgba(228, 228, 228, 1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  /deep/ .v-cascader-wrapper {
    width: 100%;
    .el-cascader {
      width: 100% !important;
    }
  }
}
</style>
